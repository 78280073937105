import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, Injectable, Input, Directive, HostListener, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
function LoadingSpinnerComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div");
  }
}
function LoadingSpinnerComponent_p_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.message);
  }
}
class DefaultSpinnerComponent {}
DefaultSpinnerComponent.ɵfac = function DefaultSpinnerComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DefaultSpinnerComponent)();
};
DefaultSpinnerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DefaultSpinnerComponent,
  selectors: [["ng-component"]],
  decls: 1,
  vars: 0,
  consts: [[1, "lds-dual-ring"]],
  template: function DefaultSpinnerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "div", 0);
    }
  },
  styles: [".lds-dual-ring{display:inline-block;width:80px;height:80px}.lds-dual-ring:after{content:\" \";display:block;width:45px;height:45px;margin:8px;border-radius:50%;border:6px solid #2959a1;border-color:#2959a1 transparent #2959a1 transparent;animation:lds-dual-ring 1.2s linear infinite}@keyframes lds-dual-ring{0%{transform:rotate(0)}to{transform:rotate(360deg)}}\n"],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultSpinnerComponent, [{
    type: Component,
    args: [{
      template: '<div class="lds-dual-ring"></div>',
      encapsulation: ViewEncapsulation.None,
      styles: [".lds-dual-ring{display:inline-block;width:80px;height:80px}.lds-dual-ring:after{content:\" \";display:block;width:45px;height:45px;margin:8px;border-radius:50%;border:6px solid #2959a1;border-color:#2959a1 transparent #2959a1 transparent;animation:lds-dual-ring 1.2s linear infinite}@keyframes lds-dual-ring{0%{transform:rotate(0)}to{transform:rotate(360deg)}}\n"]
    }]
  }], null, null);
})();
class LoadingSpinnerConfig {
  constructor() {
    this.component = DefaultSpinnerComponent;
  }
}
LoadingSpinnerConfig.ɵfac = function LoadingSpinnerConfig_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LoadingSpinnerConfig)();
};
LoadingSpinnerConfig.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: LoadingSpinnerConfig,
  factory: LoadingSpinnerConfig.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoadingSpinnerConfig, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class LoadingSpinnerComponent {
  constructor(_spinnerConfig, injector) {
    this._spinnerConfig = _spinnerConfig;
    this.injector = injector;
  }
  get component() {
    return this._spinnerConfig.component;
  }
}
LoadingSpinnerComponent.ɵfac = function LoadingSpinnerComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LoadingSpinnerComponent)(i0.ɵɵdirectiveInject(LoadingSpinnerConfig), i0.ɵɵdirectiveInject(i0.Injector));
};
LoadingSpinnerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LoadingSpinnerComponent,
  selectors: [["dp-loading-spinner"]],
  inputs: {
    message: "message"
  },
  decls: 3,
  vars: 3,
  consts: [[1, "dp-spinner-container"], [4, "ngComponentOutlet", "ngComponentOutletInjector"], [4, "ngIf"]],
  template: function LoadingSpinnerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵtemplate(1, LoadingSpinnerComponent_div_1_Template, 1, 0, "div", 1)(2, LoadingSpinnerComponent_p_2_Template, 2, 1, "p", 2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngComponentOutlet", ctx.component)("ngComponentOutletInjector", ctx.injector);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.message);
    }
  },
  dependencies: [i2.NgComponentOutlet, i2.NgIf],
  styles: [".dp-spinner-container{display:flex;flex-direction:column;justify-content:center;align-items:center;position:fixed;z-index:500;background-color:#fff;inset:0}.dp-spinner-container p{font-size:18px;margin-top:10px}.dp-spinner-parent,[dp-spinner-parent]{position:relative;min-height:100px}.dp-spinner-parent .dp-spinner-container,[dp-spinner-parent] .dp-spinner-container{position:absolute}\n"],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoadingSpinnerComponent, [{
    type: Component,
    args: [{
      selector: 'dp-loading-spinner',
      encapsulation: ViewEncapsulation.None,
      template: "<div class=\"dp-spinner-container\">\r\n    <div *ngComponentOutlet=\"component; injector: injector\"></div>\r\n    <!-- <div class=\"lds-dual-ring\"></div> -->\r\n    <p *ngIf=\"message\">{{message}}</p>\r\n</div>",
      styles: [".dp-spinner-container{display:flex;flex-direction:column;justify-content:center;align-items:center;position:fixed;z-index:500;background-color:#fff;inset:0}.dp-spinner-container p{font-size:18px;margin-top:10px}.dp-spinner-parent,[dp-spinner-parent]{position:relative;min-height:100px}.dp-spinner-parent .dp-spinner-container,[dp-spinner-parent] .dp-spinner-container{position:absolute}\n"]
    }]
  }], function () {
    return [{
      type: LoadingSpinnerConfig
    }, {
      type: i0.Injector
    }];
  }, {
    message: [{
      type: Input
    }]
  });
})();
class SpinnerParentDirective {
  constructor(_element) {
    this._element = _element;
  }
  adjustSpinnerLocations() {
    if (this._spinners) {
      this._spinners.forEach(spinner => {
        spinner.style.top = this._element.nativeElement.scrollTop + 'px';
        if (this._element.nativeElement.scrollTop > 0) {
          spinner.style.bottom = -this._element.nativeElement.scrollTop + 'px';
        } else {
          spinner.style.bottom = '0';
        }
      });
    }
  }
  ngAfterViewInit() {
    this._spinners = this._element.nativeElement.querySelectorAll('.dp-spinner-container');
    this.adjustSpinnerLocations();
  }
  onScroll() {
    this.adjustSpinnerLocations();
  }
}
SpinnerParentDirective.ɵfac = function SpinnerParentDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SpinnerParentDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
SpinnerParentDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: SpinnerParentDirective,
  selectors: [["", "dp-spinner-parent", ""], ["", 8, "dp-spinner-parent"]],
  hostBindings: function SpinnerParentDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("scroll", function SpinnerParentDirective_scroll_HostBindingHandler() {
        return ctx.onScroll();
      });
    }
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpinnerParentDirective, [{
    type: Directive,
    args: [{
      selector: '[dp-spinner-parent],.dp-spinner-parent'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    onScroll: [{
      type: HostListener,
      args: ['scroll']
    }]
  });
})();
class LoadingSpinnerModule {}
LoadingSpinnerModule.ɵfac = function LoadingSpinnerModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LoadingSpinnerModule)();
};
LoadingSpinnerModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: LoadingSpinnerModule
});
LoadingSpinnerModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoadingSpinnerModule, [{
    type: NgModule,
    args: [{
      declarations: [LoadingSpinnerComponent, DefaultSpinnerComponent, SpinnerParentDirective],
      imports: [CommonModule],
      exports: [LoadingSpinnerComponent, DefaultSpinnerComponent, SpinnerParentDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-loading-spinner
 */

/**
 * Generated bundle index. Do not edit.
 */

export { DefaultSpinnerComponent, LoadingSpinnerComponent, LoadingSpinnerConfig, LoadingSpinnerModule, SpinnerParentDirective };
